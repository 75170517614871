.terms-document {
    width: 100%;
    height: calc(100vh - 166px);
    border: none;
}

.terms-container {
    padding-top: 100px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.terms-document-mobile {
    width: 100%;
    height: 80vh;
    border: none;
}

.pb-6 {
    padding-bottom: 6px;
}

.download-pdf-btn {
    padding: 10px 20px;
    border-radius: 5px;
    position: absolute;
    right: 26px;
}
