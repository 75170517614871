.pricing-tables .ant-table-container table > thead > tr:first-child th:last-child {
    text-align-last: left;
    background-color: #F0F2F6;
}
.pricing-tables .ant-table-thead > tr > th:last-child {
    text-align-last: left;
    background-color: rgba(194, 163, 116, 0.08) !important;
    background: rgba(194, 163, 116, 0.08) !important;
    border: 1px solid #C2A374;
    border-top: 0px !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
    @media(max-width: 1200px) {
        border-left: 0px !important; 
        border-right: 0px !important;  
        border-bottom: 0px !important;
        background-color: #F0F2F6 !important;
        background: #F0F2F6 !important;
    }

}

.pricing-tables .ant-table-thead > tr > th {
    text-align-last: left;
    background-color: #F0F2F6 !important;
    background: #F0F2F6 !important;
    
}

.unit-pricing-tables .ant-table-container table > thead > tr:first-child th:last-child {
    text-align-last: left;
    background-color: #F0F2F6;
}

.unit-pricing-tables .ant-table-thead > tr > th:last-child {
    text-align-last: left;
    @media(max-width: 1200px) {
        border-left: 0px !important; 
        border-right: 0px !important;  
        border-bottom: 0px !important;
        background-color: #F0F2F6 !important;
        background: #F0F2F6 !important;
    }

}

.unit-pricing-tables .ant-table-thead > tr > th {
    text-align-last: left;
    background-color: #F0F2F6 !important;
    background: #F0F2F6 !important;
    
}

.service-heading {
    text-align-last: left;
    background-color: #F0F2F6 !important;
    background: #F0F2F6 !important;

}


.prices td{
    padding: 0% !important;
}

.pricing-tables .ant-table-tbody > tr > td {
    font-family: 'Graphik-Regular';
    font-size: 14px;
}

.pricing-tables table, td, th{
    border: 1px solid rgba(0, 0, 0, 0.08);
}

.unit-pricing-tables .ant-table-tbody > tr > td {
    font-family: 'Graphik-Regular';
    font-size: 14px;
}

.unit-pricing-tables table, td, th{
    border: 1px solid rgba(0, 0, 0, 0.08);
}
// tr.title td {
//     border-left: 0px solid !important;
//     border-right: 0px solid !important;
// }

.pricing-tables table, td:first-child {
    border-left: 0px !important;
    border-right: 0px !important;   
    @media(max-width: 1200px) {
        border-bottom: 0px !important;
    }
}
.pricing-tables table {
    background: white;

}
.pricing-tables td:last-child{
    border-left: 1px solid #C2A374  !important;
    border-right: 1px solid #C2A374  !important;
    background: rgba(194, 163, 116, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
    @media(max-width: 1200px) {
        border-left: 0px !important; 
        border-right: 0px !important;  
        border-bottom: 0px !important;
        background-color: white !important;
        background: white !important;
    }
}

.unitpricing-tables table, td:first-child {
    border-left: 0px !important;
    border-right: 0px !important;   
    border-bottom: 0px !important;
    
}
.unit-pricing-tables table {
    background: white;

}
.unit-pricing-tables td:last-child{
    border-left: 0px !important; 
    border-right: 0px !important;  
    border-bottom: 0px !important;
    background-color: white !important;
    background: white !important;
    
}

#check > span{
    border: 5px solid rgba(39, 109, 215, 0.008);
    border-radius: 15px;
}

#check-brown > span{
    border: 5px solid rgba(194, 163, 116, 0.008);;
    border-radius: 15px;
}

.prices .ant-card-body{
    padding: 0px !important;
}

.pricing-tables .ant-table table{
    border: 0px !important;
}
.unit-pricing-tables .ant-table table{
    border: 0px !important;
}
.prices .ant-table table{
    border: 0px !important;
}
.prices .ant-table-tbody > tr > td{
    border: 0px !important;
}
.pricing-tables .ant-table-container table > thead > tr:first-child th:last-child{
    border-top: 0px !important;
}

.unit-pricing-tables .ant-table-container table > thead > tr:first-child th:last-child{
    border-top: 0px !important;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header   {
    font-family: 'Graphik-Medium';
    font-size: 14px;
}

.ant-collapse-icon-position-right > .ant-collapse-item{
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding: 10px;
}

.ant-collapse-icon-position-right  > .ant-collapse-item-active  {
    background: #F0F0F0;
}

.ant-collapse-header[expanded="true"]{
    background-color: black;
}

.pricing-tables .ant-table-tbody > tr > td{
    border:1px solid rgba(0, 0, 0, 0.08) ;
    
}
.unit-pricing-tables .ant-table-tbody > tr > td{
    border:1px solid rgba(0, 0, 0, 0.08) ;
    
}

.ant-tabs-tab-btn{
    font-family: 'Graphik-Regular';
    font-size: 14px;
}

.enterprise-setting {
    background-color: rgba(194, 163, 116, 0.08);
    border-left: 1px solid #C2A374;
    border-right: 1px solid #C2A374;
    @media(max-width: 1200px) {
        background-color: white;
        border-left: none;
        border-right: none;
    }
}

.unit-price-table {
    .ant-table-wrapper {
        width: 50%;
        @media (max-width: 800px) {
            width: 100%;
        }
    }
}