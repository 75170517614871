.navbar{
    background: #ffffff;
    opacity: 0.85;
    padding: 10px 10% !important;
    
    .login-btn {
        color: #2B3240;
        background-color: transparent;
        background-image: none;
        border: 1.5px solid #2B3240;
        padding: 12px 40px;
        &:hover {
            color: #ffffff;
            background-color: #2B3240;
            background-image: none;
            border: 1.5px solid #2B3240;
        }
    }
    .signup-btn {
      border: 1px solid #C2A374 !important;
      border-radius: 4px;
      padding: 16px ;
      gap: 10px;
    }
  
}


.navbar-light .navbar-nav .nav-link {
    
    @media(min-width: 992px) {
      padding: 8px 15px;
    }
}
/* #131415; */
.rel{
    position: relative;
    height: 100vh;
    overflow: hidden;
    @media (max-width: 991px) {
      height: unset;
    }
}

.cta-text{
    position: absolute;
    top: 32%;
    left: 0;
    right: 0;
    width: 65%;
    max-width: 991px;
    color: white;
    font-size: 26px;
    pointer-events: none;
    font-weight: 600;
    margin: 0 auto;
    @media (max-width: 390px){
        font-size: 16px;
    }


    @media (max-width: 992px){
        bottom: 0;
        left: 0;
        right: 0;
        max-width: none;
        width: 100%;
    }


    &> div{
        margin-bottom: 25px;
    }
    .btn-dark{
        display: block;
        margin: auto;
        padding: 12px 60px;
        background-color: #d93025;
        border: none;
        border-radius: 6px;
        font-family: 'Graphik-Regular';
        font-size: 16px;
        font-weight: 900;
        pointer-events: all;
        &:hover{
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);
            background-color: #ad0b00; 
        }
        &:not(:disabled):not(.disabled):active{
            background-color: #d93025;
            border: none;
        }
        &:focus, &.focus{
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.3);  
        }
    }

    .get-started-btn {
      display: block;
      margin: auto;
      padding: 20px;
      background: #C2A374;
      border: none;
      border-radius: 4px;
      pointer-events: all;
      &:hover{
        box-shadow: 0 5px 20px 0 rgba(197, 155, 155, 0.3);  
        background-color: #C2A374; 
      }
      &:not(:disabled):not(.disabled):active{
          background-color: #C2A374;
          border: none;
      }
      &:focus, &.focus{
          box-shadow: 0 5px 20px 0 rgba(197, 155, 155, 0.3);  
      }
      
      @media (max-width: 991px) {
        padding: 12px 16px;
      }
    }
    a:hover{
        text-decoration: none;
    }
  
}

.custom-nav{
    z-index: 20;
    position: fixed;
    width: 100%;
    top: 0;
}
.navbar-collapse {
    flex-basis: unset;
    flex-grow: unset;
    @media (max-width: 991px) {
        flex-basis: 100%;
        flex-grow: 1;
    }
}
.navbar-light{
    position: relative;
}
.navbar-toggler{
    position: relative;
    left: 0;
}

.disable-drag{
    pointer-events: none;
}
.header-sec{
    visibility: hidden;
    &.visible{
        visibility: unset;
    }
}

.display-button-large {
  @media (max-width: 991px){
    display: none;
  }
}

.display-button-small {
  @media (min-width: 992px){
    display: none;
  }
}

.navbar-brand {
  display: inline-block;
  padding: 0px;
  margin: 0px;
  line-height: 20px;
  white-space: nowrap;
}

#vs-container {
  top: 0;
  width: 100%;
  height: 100%;
  // @media (max-width: 991px) {
  //   height: calc(100vh - 250px);
  // }
}

.hotspot {
	position: absolute;
	width: 30px;
	height: 30px;
}

.hotspotImage {
	text-align: center;
	width: 100%;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}


.tooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 999;
    bottom: 150%;
    left: 0%;
    margin-left: -50px;
    
    /* Fade in tooltip - takes 1 second to go from 0% to 100% opac: */
    opacity: 0;
    transition: opacity 1s;
  }
  
  .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  #loading-container{
    position:relative;
    // width: 100vw;
    // height: 100vh;
  }
  
  .loadingImage {
    // width: 100%;
    // height: 100%;
    //filter: blur(8px);
    //-webkit-filter: blur(8px);
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  
  .logo {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin: 0 auto;
  }
  
  .profile-main-loader{
    left: 50% !important;
    margin-left:-40px;
    position: fixed !important;
    top: 50% !important;
    margin-top: -40px;
    width: 45px;
    z-index: 10 !important;
    &.tour {
      position: absolute !important;
      top: 0 !important;
      left: 50% !important;
      margin: 0 !important;
      transform: translate(-50%, -50%) !important;
      width: 45px;
    }
}

.ant-select-selection-item-content > p > .suggested-by-artist-tag {
 display: none;
}

.profile-main-loader .loader {
  position: relative;
  margin: 0px auto;
  width: 80px;
  height:80px;
}
.profile-main-loader .loader:before {
  // content: '';
  display: block;
  padding-top: 100%;
}


.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    height: calc(100vh - 165px);
    -webkit-transform-origin: center center;
        -ms-transform-origin: center center;
            transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }
  
  .loader-path {
    stroke-dasharray: 150,200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }
  @-webkit-keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes rotate {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -124;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1,200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89,200;
      stroke-dashoffset: -124;
    }
  }
  @-webkit-keyframes color {
    0% {
      stroke: #808080;
    }
    40% {
      stroke: #808080;
    }
    66% {
      stroke: #808080;
    }
    80%, 90% {
      stroke: #808080;
    }
  }
  @keyframes color {
    0% {
      stroke: #808080;
    }
    40% {
      stroke: #808080;
    }
    66% {
      stroke: #808080;
    }
    80%, 90% {
      stroke: #808080;
    }
  }

.navbar {
  opacity: 0.98;
}

.permission-text{
  position: absolute;
  top: 50%;
  left: 25%;
  right: 0;
  width: 65%;
  max-width: 750px;
  color: black;
  font-size: 26px;
  font-weight: 600;
  margin: 0 auto;
  @media (max-width: 390px){
      font-size: 16px;
  }
  @media (max-width: 992px){
      bottom: 0;
      left: 40%;
      right: 0;
      max-width: none;
      width: 100%;
  }
  @media (max-width: 500px){
    bottom: 0;
    left: 20%;
    font-size: 18px;
    right: 0;
    max-width: none;
    width: 100%;
  }
  @media (max-width: 250px){
    bottom: 0;
    left: 10%;
    font-size: 15px;
    right: 0;
    max-width: none;
    width: 100%;
  }
  &> div{
      margin-bottom: 25px;
  }
  
}

.gyro-permission {
  z-index: 999;
  right: 0;
  position: absolute;
  margin: auto;
  text-align: center;
  top: 0;
  background: white;
  border-radius: 4px;
  width: 75%;
  padding: 20px;
  box-shadow: 0 2px 4px 0 lightgrey;
  left: 0;
  top: 33%;
}

.header-2 {
  // background: #2B3240;
  height: 100vh;
  padding-left: 10%;
  // background: white;
  // padding-top: 80px;
  // padding-bottom: 80px;
  overflow: hidden;

  @media (max-width: 991px) {
    height: unset;
    margin-top: 100px;
    // padding-top: 40px;
    // padding-bottom: 40px;
    // overflow: hidden;
  }
}

.model-viewer-pos {
  @media (min-width: 992px) {
    position: relative;
    right: 27%;
    // border-right: 27px solid #bfab84;
  }
  @media (max-width: 991px) {
    margin-bottom: 40px;
  }
}

.model-right-pd {
  @media (min-width: 992px) {
    padding-right: 8%;
  }
}

.get-started-btn-blue {
  padding: 20px;
  cursor: default;
  background: #276DD7;
  border: none;
  border-radius: 4px;
  pointer-events: all;
  &:hover{
    box-shadow: 0 5px 20px 0 rgba(158, 155, 197, 0.3);  
    background-color: #276DD7;
    ; 
  }
  &:not(:disabled):not(.disabled):active{
      background-color: #276DD7;
      border: none;
  }
  &:focus, &.focus{
      box-shadow: 0 5px 20px 0 rgba(197, 155, 155, 0.3);  
  }
  
  @media (max-width: 991px) {
    padding: 12px 16px;
  }
}

.header-flex-no-wrap {
  
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
}

.navbar-border-bottom {
  // box-shadow: 0px 4px 40px -12px rgba(0, 0, 0, 0.12); 
  border-bottom: 1px solid #D9D9D9;
}

.header-text-style {
  @media (max-width: 991px) {
    text-align: center;
    justify-content: center;
  }

  .small-heading {
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }

  .big-heading {
    @media (max-width: 991px) {
      font-size: 32px;
      font-weight: 700;
    }
  }

}