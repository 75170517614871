.layout-container {
    background: #F9F9F9;
    overflow-y: scroll;
    height: 100vh;
}

.ant-divider-vertical {
    position: relative;
    top: -0.06em;
    display: inline-block;
    height: 28px;
    margin: 0 8px;
    vertical-align: middle;
    border-top: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.06);
}

.selector {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: auto;
        width: auto;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        display: flex;
        align-items: center;
        text-align: left;
        color: #737373;
    }
}

.outer-container {
    width: 100%;
    height: 230px;
    background: #FFFFFF;
    border: 1px solid #E3E2E2;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.outer-container:hover {
    transition: box-shadow .3s;
    box-shadow: 0 0 11px rgba(33, 33, 33, .2);
}

.outer-container:hover .icon-container {
    opacity: 1;
}

.inner-container {
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;

    .img-cont {
        width: 100%;
        max-height: 230px;
    }
}

.icon-container {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    right: 0px;
    top: 10px;
    text-align: center;
}

.expand-icon {
    position: absolute;
    right: 0px;
    top: 0px;
    margin-right: 20px;
    background: rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    font-size: 28px;
    border-radius: 2px;
    cursor: pointer;
}

.img-details-container {
    position: absolute;
    left: 0px;
    bottom: 0px;
    margin-left: 20px;
    margin-bottom: 20px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 8px;
    line-height: 100%;
    border: 1px solid #E3E2E2;
}

.img-modal {
    .ant-modal-body {
        min-height: 256px;
    }

    .ant-modal-close-icon {
        color: #FFFFFF;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        padding: 5px;
    }
}

.img-loader {
    height:100%;
    min-height: 256px;
    background: white;
}

.render-img {
    max-height: 90vh;
    min-width: 25vw;
}

table,
td,
th {
    border: none
}

.password-field {
    padding: 6px 12px 6px 12px;
    display: flex;
    border-radius: 4px;
    align-items: flex-end;
    color: #737373;
}

.submit-btn {
    width: 400px;
    margin-top: 6px;
    background: #D93025;
    border-radius: 4px;
    height: 40px !important;
    border: none;

    .ant-btn>span {
        font-weight: 400;
        font-size: 14px;
    }

    &:focus {
        outline: none;
        background: #D93025;
        box-shadow: none;
    }

    &:hover {
        background: #D93025;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
    }
}

.form-container {
    background: #FFFFFF;
    height: 100vh;
}

.loader-container {
    height: 100px;
    width: 100%;

    .ant-spin-text {
        color: black;
    }
}

.login-form {
    min-width: 400px;
}

.input-form {

    .ant-input {
        border-radius: 4px !important;
        border: solid 1px #e3e3e3 !important;
        background-color: rgba(244, 244, 244, 0.5) !important;
        height: 40px !important;
        color: #333333;
        width: 400px;
    }

    .ant-form-item-required::before {
        color: #333333;
    }

    textarea.ant-input {
        height: 100px !important;
    }

}

.custom-frm {

    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 4px;
    padding-top: 80px;

    p {
        margin-bottom: 20px;
    }

    .ant-input-affix-wrapper {
        position: relative;
        display: inline-block;
        width: 100%;
        min-width: 0;
        padding: inherit;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: none;
        border-radius: 2px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        display: -ms-inline-flexbox;
        display: inline-flex;
        max-height: inherit;
    }

    .ant-input-prefix {
        margin-right: 0px;
    }

    .ant-input-affix-wrapper>input.ant-input {
        padding-left: 30px;
    }

    .ant-form-item-control-input-content {
        position: relative;

        .anticon.anticon-lock {
            position: absolute;
            top: 35%;
            left: 2%;
        }
    }

    .ant-form-item-control {
        position: relative;
        line-height: 40px;
        zoom: 1;
    }
}

.branding-image {
    height: 100vh;
    width: 100%;
}