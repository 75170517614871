.amazon-integration-container {
    padding-top: 120px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 100px;
    @media (max-width: 990px){
      padding-top: 80px;
      padding-bottom: 0px;
    }
  }

  .amazon-integration-model {
    width: 30%;
    max-width: 300px;
  }

  .amazon-integration-details {
    margin: 80px;
    display: flex;
    align-items: center;
    width: 70%;
  }

  .amazon-integration-details--mobile {
    margin: 10px;
  }

  .heading-background {
    padding: 50px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .amazon-integration-button {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .text-center {
    text-align: center;
  }
